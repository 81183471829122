<template>
  <div class="main-page-wrapper overflow-hidden">
    <Header />
    <div class="fancy-hero-privacy ">

      <div class="container">
        <div class=" col-lg-11 col-md-10 m-auto">
          <h1 class="font-gordita-bold">
            Privacy Policy
          </h1>
        </div>
      </div> <!-- /.bg-wrapper -->
    </div>
    <!-- portfolio details start -->
    <div class="hero-banner-seven mt-75 mb-150 md-mb-100">
      <div class="container">
        <div class="title-style-nine mb-45">
          <p>Heu cares about your privacy and we are committed to protecting your data. Below are the main points of our privacy policy. We hope you will read it carefully and contact us with any questions or concerns at heugroup@gmail.com.</p>
          <ul>
            <li>
              • As a policy, we do not monitor text evaluated by Heu.
            </li>
            <li> • No accounts and personal information are linked with the data you provide.
            </li>
            <li>
              • All data is encrypted in transit to our prediction servers.
            </li>
            <li>
              • We use the anonymized feedback you provide to train the Heu model.
            </li>

            <li>
              • We have access to metadata from services such as Google analytics for aggregated statistics.
            </li>
            <li>• This will always be a free service; we hope to later offer a paid service with additional features.</li>
            <li>• We don't share data used for training with any external parties</li>
            <li>• We don’t advertise or use your data for any kind of targeted messaging

            </li>
          </ul>
          <br>
          <h2>Cookies</h2>
          <p>We does not use "cookies" to track or deliver web content specific to individual users' interests.</p>
          <h2>Legal requirements</h2>
          <p>Other than as required by laws that guarantee public access to certain types of information, or in response to subpoenas or other legal instruments that authorize access, personal information is not actively shared.</p>
          <h2>Communications</h2>
          <p>Heu will only use personal information collected from this site for the purpose of returning communication back to individuals who contact us via the site.</p>
          <h2>Privacy Statement Revisions:</h2>
          <p>This Privacy Statement was last revised on 01/16/2022. We may change this Privacy Statement at any time and for any reason. We encourage you to review this Privacy Statement each time you visit the web site.</p>
          <p>If we make a significant change to our Privacy Statement, we will post a notice on the homepage of our web site for a period of time after the change is made.</p>
          <h2>How to contact us</h2>
          <p>Contact us at heugroup@gmail.com.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>

</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  name: 'Privacy',
  components: {
    Header,
    Footer,
  },
}
</script>
<style scoped>
@import 'css/custom.css';
@import 'css/style.css';
@import 'css/custom-animation.css';
@import 'css/framework.css';
@import 'css/menu.css';
@import 'css/responsive.css';

h2 {
  font-family: "gordita" !important;
  font-size: 25px !important;
}

p{
  font-family: "gordita" !important;
  font-size: 18px !important;
}
li{
  font-family: "gordita" !important;
  font-size: 18px !important;
  color: rgb(84, 84, 84);
}

</style>
